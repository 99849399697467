import { combineReducers } from "redux";
import nav from "./nav";
import user from "./user";
import search from "./search";

const saleshub_theme = combineReducers({
  nav,
  user,
  search,
});

export default saleshub_theme;
