import React, { Component } from "react";
import { connect } from "react-redux";
import * as h from "./helpers";

class Footer extends Component {
  render() {
    const { nav } = this.props;
    const mainMenuItems = h.mainMenuItems(nav);
    return (
      <nav className="site-footer-nav">
        {mainMenuItems && mainMenuItems.length > 0 && (
          <ul className="site-footer-nav-list">
            {mainMenuItems.map((item, index) => {
              const itemClasses = h.itemClasses(item, "site-footer-nav-item");
              return (
                <li className={itemClasses} key={item.title + index}>
                  <span className="site-footer-nav-item-header">
                    {h.hasChildren(item) ? (
                      item.title
                    ) : (
                      <a href={item.url} title={item.title}>
                        {item.title}
                      </a>
                    )}
                  </span>
                  {h.hasChildren(item) && (
                    <ul className="site-footer-nav-sublist">
                      {item.children.map((child, index) => {
                        const childClasses = h.itemClasses(
                          child,
                          "site-footer-nav-subitem"
                        );
                        return (
                          <li
                            className={childClasses}
                            key={child.title + index}
                          >
                            {h.hasChildren(child) ? (
                              <span className="site-footer-nav-subitem-non-link">
                                {child.title}
                              </span>
                            ) : (
                              <a
                                className="site-footer-nav-subitem-link"
                                href={child.url}
                                title={child.title}
                              >
                                {child.title}
                              </a>
                            )}
                            {h.hasChildren(child) && (
                              <ul className="site-footer-nav-sub-sublist">
                                {child.children.map((grandchild, index) => {
                                  const grandchildClasses = h.itemClasses(
                                    grandchild,
                                    "site-footer-nav-sub-subitem"
                                  );
                                  return (
                                    <li
                                      className={grandchildClasses}
                                      key={grandchild.title + index}
                                    >
                                      <a
                                        className="site-footer-nav-sub-subitem-link"
                                        href={grandchild.url}
                                        title={grandchild.title}
                                      >
                                        {grandchild.title}
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        )}
        {/* <ul className="site-footer-nav-list">
          <li className="site-footer-nav-item">
            <span className="site-footer-nav-item-header">
              <a
                href="https://saleshub.manningtoncommercial.com/saleshub/quick-links/"
                title="Quicklinks"
              >
                Quicklinks
              </a>
            </span>
            <div className="setrow">
              <div className="col50 flexcol">
                <ul className="site-footer-nav-sublist">
                  <li className="site-footer-nav-subitem">
                    <a
                      className="site-footer-nav-subitem-link"
                      href="https://manningtoncommercial.myprintdesk.net/DSF/SmartStore.aspx#!/Storefront"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Business Cards"
                    >
                      Business Cards
                    </a>
                  </li>
                  <li className="site-footer-nav-subitem">
                    <a
                      className="site-footer-nav-subitem-link"
                      href="https://saleshub.manningtoncommercial.com/salestools/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Sales Tools"
                    >
                      Sales Tools
                    </a>
                  </li>
                  <li className="site-footer-nav-subitem">
                    <a
                      className="site-footer-nav-subitem-link"
                      href="https://saleshub.manningtoncommercial.com/socialmedia/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Social Media"
                    >
                      Social Media
                    </a>
                  </li>
                  <li className="site-footer-nav-subitem">
                    <a
                      className="site-footer-nav-subitem-link"
                      href="https://saleshub.manningtoncommercial.com/tradeshows/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Tradeshow Kits"
                    >
                      Tradeshow Kits
                    </a>
                  </li>
                  <li className="site-footer-nav-subitem">
                    <a
                      className="site-footer-nav-subitem-link"
                      href="https://saleshub.manningtoncommercial.com/giveaways/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Giveaways"
                    >
                      Giveaways
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col50 flexcol">
                <ul className="site-footer-nav-sublist">
                  <li className="site-footer-nav-subitem">
                    <a
                      className="site-footer-nav-subitem-link"
                      href="https://saleshub.manningtoncommercial.com/ceu/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="CEU Registration"
                    >
                      CEU Registration
                    </a>
                  </li>
                  <li className="site-footer-nav-subitem">
                    <a
                      className="site-footer-nav-subitem-link"
                      href="https://saleshub.manningtoncommercial.com/ceu-instructor/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="CEU Registration"
                    >
                      CEU Instructor Registration
                    </a>
                  </li>
                  <li className="site-footer-nav-subitem">
                    <a
                      className="site-footer-nav-subitem-link"
                      href="https://saleshub.manningtoncommercial.com/apparel/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Apparel Orders"
                    >
                      Apparel Orders
                    </a>
                  </li>
                  <li className="site-footer-nav-subitem">
                    <a
                      className="site-footer-nav-subitem-link"
                      href="mailto:sales_support@mannington.com?subject=Request for Sales Support from Mannington Sales Hub"
                      title="Sales Support"
                    >
                      Sales Support
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li className="site-footer-nav-item">
            <span className="site-footer-nav-item-header">
              <a
                href="https://saleshub.manningtoncommercial.com/saleshub/marketing-resources/"
                title="Marketing Resources"
              >
                Marketing Resources
              </a>
            </span>
            <div className="setrow">
              <div className="col50 flexcol">
                <ul className="site-footer-nav-sublist">
                  <li className="site-footer-nav-subitem">
                    <a
                      className="site-footer-nav-subitem-link"
                      href="mailto:taylor.young@mannington.com?subject=Request for Brand Logo from Mannington Sales Hub"
                      title="Logo Requests"
                    >
                      Logo Requests
                    </a>
                  </li>
                  <li className="site-footer-nav-subitem">
                    <a
                      className="site-footer-nav-subitem-link"
                      href="https://saleshub.manningtoncommercial.com/static/docs/Mannington_Email_Signature_Setup_Instructions.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Email Signatures"
                    >
                      Email Signatures
                    </a>
                  </li>
                  <li className="site-footer-nav-subitem">
                    <a
                      className="site-footer-nav-subitem-link"
                      href="https://saleshub.manningtoncommercial.com/saleshub/marketing-resources/#powerpoint-templates"
                      title="Powerpoint Templates"
                    >
                      Powerpoint Templates
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col50 flexcol">
                <ul className="site-footer-nav-sublist">
                  <li className="site-footer-nav-subitem">&nbsp;</li>
                </ul>
              </div>
            </div>
          </li>
          <li className="site-footer-nav-item" />
        </ul> */}
      </nav>
    );
  }
}

const mapStateToProps = state => {
  return {
    nav: state.nav.nav,
  };
};

export default connect(
  mapStateToProps,
  {}
)(Footer);
