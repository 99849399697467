const defaultState = {
  open: false,
};

const search = (state = defaultState, action) => {
  switch (action.type) {
    case "TOGGLE_SEARCH":
      return { ...state, open: !state.open };
    default:
      return state;
  }
};

export default search;
