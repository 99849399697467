import React from "react";
import SmoothCollapse from "react-smooth-collapse";
import { connect } from "react-redux";
import { toggleSearch } from "./actions";
import * as constants from "./constants";

function Search({ searchOpen, toggleSearch }) {
  return (
    <SmoothCollapse expanded={searchOpen}>
      <div className="search-menu">
        <h3 className="search-title">Search by Keyword</h3>
        <form action="/search" className="form search-form" method="GET">
          <a className="close" onClick={toggleSearch} />
          <ol className="form-fields">
            <li className="form-field form-field-with-placeholder">
              <label htmlFor="id_page_search">Search</label>
              <img
                src={`${constants.assetPrefix}/static/img/icons/search.357b56fbd190.svg`}
                alt="search icon"
              />
              <input
                autoFocus
                id="id_page_search"
                list="search-suggestions"
                name="SearchableText"
                placeholder="Search"
                type="search"
              />
            </li>
            <li className="form-buttons">
              <button className="btn" type="submit">
                Show my results
              </button>
            </li>
          </ol>
        </form>
      </div>
    </SmoothCollapse>
  );
}

const mapStateToProps = state => {
  return {
    searchOpen: state.search.open,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleSearch: () => dispatch(toggleSearch()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search);
