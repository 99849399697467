import React, { Component } from "react";
import SmoothCollapse from "react-smooth-collapse";
import * as h from "./helpers";

class HeaderSecondLevelItem extends Component {
  render() {
    const { item } = this.props;

    const itemClasses = h.itemClasses(item, "site-menu-sublist-item");

    return (
      <li className={itemClasses}>
        {h.hasChildren(item) ? (
          <button
            className="site-menu-sublist-item-non-link"
            title={item.title}
          >
            {item.title}
          </button>
        ) : (
          <a
            className="site-menu-sublist-item-link"
            href={item.url}
            title={item.title}
          >
            {item.title}
          </a>
        )}
        {h.hasChildren(item) && (
          <SmoothCollapse expanded={true}>
            <ul className="site-menu-sub-sublist">
              {item.children.map((child, index) => {
                const childClasses = h.itemClasses(
                  child,
                  "site-menu-sub-sublist-item"
                );
                return (
                  <li className={childClasses} key={child.title + index}>
                    <a
                      className="site-menu-sublist-item-link"
                      href={child.url}
                      title={child.title}
                    >
                      {child.title}
                    </a>
                  </li>
                );
              })}
            </ul>
          </SmoothCollapse>
        )}
      </li>
    );
  }
}

export default HeaderSecondLevelItem;
