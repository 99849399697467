export const assetPrefix = process.env.REACT_APP_ASSET_PREFIX;
export const getNavEndpoint = process.env.REACT_APP_GET_NAV_ENDPOINT;
export const getUserEndpoint = process.env.REACT_APP_GET_USER_ENDPOINT;
export const searchEndpoint = process.env.REACT_APP_SEARCH_ENDPOINT;
export const mobileBreakpointQuery = "(max-width: 1199px)";
export const publishedStateNames = [
  "all_published",
  "dealer_published",
  "internally_published",
  "rubber_published",
];
