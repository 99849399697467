import React from "react";

export default function SiteMenuToggle({ toggleMobileNav }) {
  return (
    <a
      className="site-menu-toggle"
      href="index.html"
      onClick={toggleMobileNav}
      title="Site Menu"
    >
      <span className="site-menu-toggle-bar" />
      <span className="site-menu-toggle-bar" />
      <span className="site-menu-toggle-bar" />
      <span className="site-menu-toggle-bar" />
      <span className="site-menu-toggle-bar" />
      <span className="site-menu-toggle-bar" />
      <span className="sr-only">Menu</span>
    </a>
  );
}
