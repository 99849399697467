import React, { Component } from "react";
import { connect } from "react-redux";
import { getNav, getUser, toggleSearch } from "./actions";
import HeaderLogo from "./HeaderLogo";
import HeaderTopLevelItem from "./HeaderTopLevelItem";
import SiteMenuToggle from "./SiteMenuToggle";
import classNames from "classnames";
import throttle from "lodash.throttle";
import * as constants from "./constants";
import * as h from "./helpers";
import "./header.css";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      mobileNavOpen: false,
      pageScrolled: false
    };

    this.throttledPageScrolledCheck = throttle(this.pageScrolledCheck, 200);
  }

  componentDidMount() {
    const { getNav, getUser } = this.props;

    // set up and initialize mediaQuery
    this.mediaQuery = window.matchMedia(constants.mobileBreakpointQuery);
    this.mediaQuery.addListener(this.setIsMobile);
    this.setIsMobile();

    window.addEventListener("scroll", this.throttledPageScrolledCheck);

    // to adjust header height on page load if the page is already scrolled
    window.setTimeout(this.throttledPageScrolledCheck, 100);

    getNav();
    getUser();
  }

  componentWillUnmount() {
    this.mediaQuery.removeListener(this.setIsMobile);
    window.removeEventListener("scroll", this.throttledPageScrolledCheck);
  }

  contacts = () => {
    const { nav } = this.props;
    return nav.filter(item => item.title === "Contacts")[0];
  };

  pageScrolledCheck = () => {
    const { pageScrolled } = this.state;

    // Changing the point where the header height changes prevents
    // an infinite stutter where the changing header height changes the scroll
    // position and the header contracts then expands over and over again
    const changePoint = pageScrolled ? 50 : 90;
    const scrolledStatus = window.pageYOffset > changePoint;
    if (pageScrolled !== scrolledStatus) {
      this.setState({ pageScrolled: scrolledStatus });
    }
  };

  setIsMobile = () => {
    this.setState({ isMobile: this.mediaQuery.matches }, () => {
      console.log("this.state.isMobile", this.state.isMobile);
    });
  };
  toggleMobileNav = e => {
    if (e) {
      e.preventDefault();
    }
    let body = document.body;
    let htmlEl = body.parentNode;
    // let mobileNavOpenBodyStyles = "overflow: hidden; height: 100%";
    // let mobileNavClosedBodyStyles = "overflow: visible; height: auto";
    // console.log("document.body.style", document.body.style);
    // let newBodyStyle =
    //   window.getComputedStyle(body).overflow === "hidden"
    //     ? mobileNavClosedBodyStyles
    //     : mobileNavOpenBodyStyles;
    // body.style = newBodyStyle;
    htmlEl.classList.toggle("show-site-menu");
    this.setState({ mobileNavOpen: !this.state.mobileNavOpen });
  };
  toggleSearchOpen = e => {
    const { toggleSearch } = this.props;
    const { mobileNavOpen } = this.state;
    e.preventDefault();
    toggleSearch();
    if (mobileNavOpen) {
      this.toggleMobileNav();
    }
    return false;
  };

  render() {
    const { nav, user } = this.props;
    const { isMobile, pageScrolled } = this.state;
    if (process.env.NODE_ENV === "development") {
      console.log("nav", nav);
      console.log("user", user);
    }
    const contacts = this.contacts();
    const mainMenuItems = h.mainMenuItems(nav);
    const navClasses = classNames("site-menu", {
      "mt-sh-scrolled": pageScrolled
    });
    const searchNavItem = {
      review_state: constants.publishedStateNames[1],
      title: "Search",
      url: "/search/"
    };

    return (
      <React.Fragment>
        <HeaderLogo pageScrolled={pageScrolled} />
        <SiteMenuToggle toggleMobileNav={this.toggleMobileNav} />
        <nav className={navClasses}>
          <ul className="site-menu-list">
            {mainMenuItems.map((item, index) => {
              const isLastMainMenuItem = index === mainMenuItems.length - 1;
              const extraClasses = classNames({
                "site-menu-last-main-menu-item": isLastMainMenuItem
              });
              return (
                <HeaderTopLevelItem
                  isMobile={isMobile}
                  item={item}
                  extraClasses={extraClasses}
                  key={item.title + index}
                />
              );
            })}
            {contacts &&
              <HeaderTopLevelItem isMobile={isMobile} item={contacts} />}
            {user.username &&
              <React.Fragment>
                <HeaderTopLevelItem
                  extraClasses="site-menu-list-item-desktop-icon site-menu-list-item-search"
                  iconSrc={`${constants.assetPrefix}/static/icons/095__search__no_whitespace.svg`}
                  iconAlt="search icon"
                  isMobile={isMobile}
                  item={searchNavItem}
                  linkClickHandler={this.toggleSearchOpen}
                />
                <HeaderTopLevelItem
                  extraClasses="site-menu-list-item-desktop-icon site-menu-list-item-account"
                  iconSrc={`${constants.assetPrefix}/static/icons/103__user__no_whitespace.svg`}
                  iconAlt="account icon"
                  isMobile={isMobile}
                  item={user}
                />
              </React.Fragment>}

            {/* EMPTY LAST ITEM REQUIRED */}
            <li className="site-menu-list-item" />
          </ul>
        </nav>
        {/* <Search /> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    nav: state.nav.nav,
    navLoaded: state.nav.loaded,
    user: state.user.user,
    userLoaded: state.user.loaded
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getNav: () => dispatch(getNav()),
    getUser: () => dispatch(getUser()),
    toggleSearch: () => dispatch(toggleSearch())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
