import saleshub_theme from "./reducers";
import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";

const configureStore = () => {
  const middleware = [thunk];

  return createStore(
    saleshub_theme,
    applyMiddleware(...middleware)
    // composeWithDevTools(applyMiddleware(...middleware))
  );
};

export default configureStore;
