import React, { Component } from "react";
import SmoothCollapse from "react-smooth-collapse";
import classNames from "classnames";
import * as h from "./helpers";
import HeaderSecondLevelItem from "./HeaderSecondLevelItem";

class HeaderTopLevelItem extends Component {
  state = {
    mobileExpanded: false,
  };

  displayExpanded = () => {
    return !this.props.isMobile || this.state.mobileExpanded;
  };

  handleClick = e => {
    const { isMobile } = this.props;
    const { mobileExpanded } = this.state;

    if (isMobile) {
      this.setState({ mobileExpanded: !mobileExpanded });
    }
  };

  itemTitle = () => {
    const { iconAlt, iconSrc, isMobile, item } = this.props;
    if (iconSrc && !isMobile) {
      return <img src={iconSrc} alt={iconAlt} />;
    } else {
      return item.title;
    }
  };

  render() {
    const { extraClasses, isMobile, item, linkClickHandler } = this.props;
    const itemClasses = h.itemClasses(
      item,
      classNames("site-menu-list-item", extraClasses, {
        "site-menu-list-item-show-sublist": isMobile,
      })
    );
    const expanded = this.displayExpanded();
    const buttonClasses = classNames("site-menu-list-item-non-link", {
      "mc-sh-expanded": expanded,
    });

    return (
      <li className={itemClasses}>
        {h.hasChildren(item) ? (
          <button
            className={buttonClasses}
            title={item.title}
            onClick={this.handleClick}
          >
            {this.itemTitle()}
          </button>
        ) : (
          <a
            className="site-menu-list-item-link"
            href={item.url}
            title={item.title}
            onClick={linkClickHandler}
          >
            {this.itemTitle()}
          </a>
        )}
        {h.hasChildren(item) && (
          <SmoothCollapse expanded={expanded}>
            <ul className="site-menu-sublist">
              {item.children.map((child, index) => {
                return (
                  <HeaderSecondLevelItem
                    item={child}
                    key={child.title + index}
                  ></HeaderSecondLevelItem>
                );
              })}
            </ul>
          </SmoothCollapse>
        )}
      </li>
    );
  }
}

export default HeaderTopLevelItem;
