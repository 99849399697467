import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
import Search from "./Search";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./configureStore";
import "./scoped-bootstrap-3.scss";
import "./cc.css";

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Header />
  </Provider>,
  document.getElementById("saleshub-react-header")
);

ReactDOM.render(
  <Provider store={store}>
    <Search />
  </Provider>,
  document.getElementById("search-main")
);

ReactDOM.render(
  <Provider store={store}>
    <Footer />
  </Provider>,
  document.getElementById("saleshub-react-footer")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
