// import { togleSearch } from "./actions";
import classNames from "classnames";
import * as constants from "./constants";

export const hasChildren = item => {
  return item.children && item.children.length > 0;
};

export const isAdminOnly = item => {
  return constants.publishedStateNames.indexOf(item.review_state) == -1;
};

export const itemClasses = (item, className) => {
  return classNames(className, {
    "mc-sh-admin-only": isAdminOnly(item),
  });
};

export const isEmptyObject = obj => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const mainMenuItems = nav => {
  return nav.reduce((items, item) => {
    if (item.title !== "Contacts") {
      items.push(item);
    }
    return items;
  }, []);
};
