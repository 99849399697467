import * as h from "../helpers";
import * as constants from "../constants";

const defaultState = {
  user: {},
  loaded: false,
};

const user = (state = defaultState, action) => {
  switch (action.type) {
    case "GET_USER_SUCCESS":
      return { ...state, user: transformUser(action.response), loaded: true };
    default:
      return state;
  }
};

const transformUser = user => {
  if (h.isEmptyObject(user)) {
    return user;
  } else {
    const children = user.user_actions.map(action => {
      return { ...action, review_state: constants.publishedStateNames[1] };
    });
    return {
      ...user,
      children: children,
      review_state: constants.publishedStateNames[1],
      title: "Account",
    };
  }
};

export default user;
