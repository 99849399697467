import axiosMaker from "axios";
import * as constants from "../constants";
import * as h from "../helpers";

const axios = axiosMaker.create();
axios.defaults.withCredentials = true;

const getNavCacheBusted = () => {
  return axios.get(constants.getNavEndpoint, {
    params: { d: String(Date.now()), currentpath: window.location.pathname },
  });
};

const getUserCacheBusted = () => {
  return axios.get(constants.getUserEndpoint, {
    params: { d: String(Date.now()) },
  });
};

export const getNav = () => dispatch => {
  return axios
    .get(constants.getNavEndpoint, {
      params: { currentpath: window.location.pathname },
    })
    .then(res => {
      if (res.data.length === 0) {
        return getNavCacheBusted();
      } else {
        return res;
      }
    })
    .then(
      res => {
        dispatch({ type: "GET_NAV_SUCCESS", response: res.data });
      },
      err => {
        console.log("In getNav, err:", err);
      }
    );
};

export const getUser = () => dispatch => {
  return axios
    .get(constants.getUserEndpoint)
    .then(res => {
      if (h.isEmptyObject(res.data)) {
        return getUserCacheBusted();
      } else {
        return res;
      }
    })
    .then(
      res => {
        dispatch({ type: "GET_USER_SUCCESS", response: res.data });
      },
      err => {
        console.log("In getUser, err:", err);
      }
    );
};

export const toggleSearch = e => dispatch => {
  if (e) {
    e.preventDefault();
  }
  dispatch({ type: "TOGGLE_SEARCH" });
};
