const defaultState = {
  nav: [],
  loaded: false,
};

const nav = (state = defaultState, action) => {
  switch (action.type) {
    case "GET_NAV_SUCCESS":
      return { ...state, nav: action.response, loaded: true };
    default:
      return state;
  }
};

export default nav;
